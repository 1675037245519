import { Collapse, Drawer, DrawerContent, Flex, Icon, Text, useMediaQuery } from '@chakra-ui/react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useFeatureFlags } from '@wanda/shared/src/hooks/useFeatureFlags'
import { type UserRole, useRoles } from '@wanda/shared/src/hooks/useRoles'
import React, { useEffect, useState } from 'react'
import { MdExpandLess, MdExpandMore } from 'react-icons/md'
import { Link, useLocation } from 'react-router-dom'

import { type RouteType, SidebarRoutes, createRouteFilter } from 'consts/Routes'
import { SIDEBAR_WIDTH, USE_DRAWER } from 'styles/consts'

export const Sidebar = function Sidebar({ open, onClose }: { open: boolean; onClose: () => void }) {
  const [shouldUseDrawer] = useMediaQuery(USE_DRAWER)

  return shouldUseDrawer ? (
    <Drawer placement="left" isOpen={open} onClose={onClose}>
      <DrawerContent background="gray.700" overflowY="auto">
        <Menu />
      </DrawerContent>
    </Drawer>
  ) : open ? (
    <Menu width={SIDEBAR_WIDTH} />
  ) : null
}

function Menu({ width }: { width?: string }) {
  const roles = useRoles()
  const { featureFlags } = useFeatureFlags()
  const { pathname } = useLocation()
  const routes = SidebarRoutes.filter(createRouteFilter({ roles, featureFlags }))
  return (
    <Flex flexDirection="column" background="gray.700" color="white" w={width} minW={width}>
      {routes.map((route) => (
        <MenuItem
          key={route.path}
          pathname={pathname}
          route={route}
          roles={roles}
          featureFlags={featureFlags || {}}
        />
      ))}
    </Flex>
  )
}

function MenuItem({
  route: { path, name, routes, Icon: RouteIcon },
  pathname,
  roles,
  featureFlags,
}: {
  route: RouteType
  pathname: string
  roles: UserRole[]
  featureFlags: Record<string, boolean | undefined>
}) {
  const subRoutes = routes ? routes().filter(createRouteFilter({ roles, featureFlags })) : []
  const active = pathname.startsWith(path)
  const [open, setOpen] = useState(active)
  useEffect(() => setOpen(active), [active])
  return (
    <>
      <Link to={path}>
        <Item active={active}>
          <Icon as={RouteIcon} fontSize="20px" />
          <Text as="span" ml="2">
            {name}
          </Text>
          {subRoutes.length > 0 ? (
            <span
              onClick={(event) => {
                event.preventDefault()
                event.stopPropagation()
                setOpen((open) => !open)
              }}
            >
              {open ? <MdExpandLess /> : <MdExpandMore />}
            </span>
          ) : null}
        </Item>
      </Link>

      {subRoutes.length > 0 ? (
        <Collapse in={open}>
          {routes
            ? routes().map(({ path, Icon: RouteIcon, name }) => (
                <Link key={`sidebar-${path}`} to={path}>
                  <Item subRoute active={pathname === path}>
                    <Icon as={RouteIcon} size="16px" />
                    <Text as="span" ml="2">
                      {name}
                    </Text>
                  </Item>
                </Link>
              ))
            : null}
        </Collapse>
      ) : null}
    </>
  )
}

const Item = styled.span<{ subRoute?: boolean; active: boolean }>`
  padding: 16px;
  display: flex;
  align-items: center;
  background-color: var(--chakra-colors-gray-700);
  transition: background-color ease-in-out 0.25s;
  font-weight: var(--chakra-fontWeights-light);

  &:hover {
    background-color: var(--chakra-colors-gray-500);
    transition: background-color ease-in 0.25s;
  }

  ${({ subRoute }) =>
    subRoute
      ? css`
          margin-top: -8px;
          padding-left: 24px;
          font-size: 0.8em;
        `
      : css``}

  ${({ active }) =>
    active
      ? css`
          font-weight: var(--chakra-fontWeights-semibold);
        `
      : css``}
`
