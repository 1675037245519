import { request } from '../api-client'
import { opsBaseUrl as baseUrl } from '../config'

type RefreshCapacityRequest = any
type RefreshCapacityResponse = any

export function refreshPlandayCapacity(
  data: RefreshCapacityRequest,
  token: string,
): Promise<RefreshCapacityResponse> {
  return request<RefreshCapacityResponse>({
    baseUrl,
    url: '/v1/shifts/refresh-planday/',
    method: 'POST',
    body: data,
    token,
  })
}
