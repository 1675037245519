import { useAuth0 } from '@auth0/auth0-react'
import { Flex, Input } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { Account, Product, ProductCategory, UserType } from '@wanda/shared'
import { fetchItems } from '@wanda/shared/src/api-client/routes/items'
import { fetchListings } from '@wanda/shared/src/api-client/routes/listing'
import { fetchOrders } from '@wanda/shared/src/api-client/routes/orders'
import { fetchServiceOrders } from '@wanda/shared/src/api-client/routes/service-orders'
import { useFeatureFlags } from '@wanda/shared/src/hooks/useFeatureFlags'
import Fuse from 'fuse.js'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { sentenceCase } from 'sentence-case'
import useKeyboardShortcut from 'use-keyboard-shortcut'

import { type RouteType, SidebarRoutes } from 'consts/Routes'
import { formatTimeSlot } from 'utils/date-format'

import { fetchAccounts } from '@wanda/shared/src/api-client/routes/accounts'
import { fetchUsersV2 } from '@wanda/shared/src/api-client/routes/users'
import { ResultSection } from './ResultSection'

const shortcutsOptions = {
  overrideSystem: true,
  ignoreInputFields: false,
  repeatOnHold: false,
}

function useDebounce<T>(value: T, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay], // Only re-call effect if value or delay changes
  )

  return debouncedValue
}

const mapRoute = (route: RouteType) => ({
  id: route.name,
  name: route.name,
  link: route.path,
  featureFlag: route.featureFlag,
})

const ACTIONS = SidebarRoutes.flatMap((route) => [
  ...(route.routes ? route.routes().map(mapRoute) : []),
  mapRoute(route),
]).filter(Boolean)

const fuse = new Fuse(ACTIONS, { keys: ['name'] })

export function Search({
  isOpen,
  useCard,
  query,
  onClose,
}: {
  isOpen: boolean
  useCard?: boolean
  query?: string
  onClose: () => void
}) {
  const navigate = useNavigate()
  const [value, setValue] = useState(query ?? '')
  const [selected, setSelected] = useState(0)
  const debouncedValue = useDebounce(value, 500)
  const { featureFlags } = useFeatureFlags()

  const { getAccessTokenSilently } = useAuth0()
  const actions = useQuery(
    ['search-actions', debouncedValue],
    async () => {
      const items = fuse
        .search(debouncedValue)
        .filter((route) => !route.item.featureFlag || featureFlags?.[route.item.featureFlag])
        .map(({ item: { id, name, link } }) => ({ id, name, link, key: `action:${id}` }))
        .slice(0, 3)
      return items
    },
    { enabled: debouncedValue.length > 2 },
  )

  const accounts = useQuery(
    ['search-accounts', debouncedValue],
    async () => {
      const token = await getAccessTokenSilently()
      return (
        await fetchAccounts({
          search: debouncedValue,
          itemsPerPage: 5,
          orderBy: 'createdAt',
          orderDir: 'DESC',
          page: 0,
          filters: undefined,
          token,
        })
      ).items.map((account) => ({
        ...account,
        key: `accounts:${account.id}`,
        link: `/accounts/${account.id}`,
      }))
    },
    { enabled: debouncedValue.length > 2 },
  )

  const users = useQuery(
    ['search-users', debouncedValue],
    async () => {
      const token = await getAccessTokenSilently()
      return (
        await fetchUsersV2({
          search: debouncedValue,
          itemsPerPage: 5,
          orderBy: 'createdAt',
          orderDir: 'DESC',
          page: 0,
          filters: undefined,
          token,
        })
      ).items.map((account) => ({
        ...account,
        key: `users:${account.id}`,
        link: `/accounts/users/${account.id}`,
      }))
    },
    { enabled: debouncedValue.length > 2 },
  )
  const orders = useQuery(
    ['search-orders', debouncedValue],
    async () => {
      const token = await getAccessTokenSilently()
      return (
        await fetchOrders({
          search: debouncedValue,
          itemsPerPage: 5,
          orderBy: 'createdAt',
          orderDir: 'DESC',
          page: 0,
          filters: undefined,
          token,
        })
      ).items.map((order) => ({
        ...order,
        key: `order:${order.id}`,
        link: `/Orders/${order.id}`,
      }))
    },
    { enabled: debouncedValue.length > 2 },
  )
  const items = useQuery(
    ['search-items', debouncedValue],
    async () => {
      const token = await getAccessTokenSilently()
      return (
        await fetchItems({
          search: debouncedValue,
          itemsPerPage: 5,
          orderBy: 'createdAt',
          orderDir: 'DESC',
          page: 0,
          filters: undefined,
          token,
        })
      ).items.map((item) => ({
        ...item,
        key: `item:${item.id}`,
        link: `/Items?search=${item.id}&selectedId=${item.id}`,
      }))
    },
    { enabled: debouncedValue.length > 2 },
  )
  const serviceOrders = useQuery(
    ['search-service-orders', debouncedValue],
    async () => {
      const token = await getAccessTokenSilently()
      return (
        await fetchServiceOrders({
          search: debouncedValue,
          itemsPerPage: 5,
          orderBy: 'createdAt',
          orderDir: 'DESC',
          page: 0,
          filters: undefined,
          token,
        })
      ).items.map((serviceOrder) => ({
        ...serviceOrder,
        key: `serviceOrder:${serviceOrder.id}`,
        link: `/service-orders/${serviceOrder.id}`,
      }))
    },
    { enabled: debouncedValue.length > 2 },
  )
  const listings = useQuery(
    ['listings', debouncedValue],
    async () => {
      const token = await getAccessTokenSilently()
      return (
        await fetchListings({
          search: debouncedValue,
          itemsPerPage: 5,
          orderBy: 'createdAt',
          orderDir: 'DESC',
          page: 0,
          filters: undefined,
          token,
        })
      ).items.map((listings) => ({
        ...listings,
        key: `listings:${listings.id}`,
        link: `/listing/${listings.id}`,
      }))
    },
    { enabled: debouncedValue.length > 2 },
  )

  const combined = [
    ...(actions.data ?? []),
    ...(accounts.data ?? []),
    ...(orders.data ?? []),
    ...(items.data ?? []),
    ...(serviceOrders.data ?? []),
    ...(listings.data ?? []),
    ...(users.data ?? []),
  ]

  const selectedKey = combined[selected]?.key

  useKeyboardShortcut(
    ['ArrowUp'],
    () => {
      if (isOpen) {
        setSelected((selected) => (selected === 0 ? combined.length - 1 : selected - 1))
      }
    },
    shortcutsOptions,
  )
  useKeyboardShortcut(
    ['ArrowDown'],
    () => {
      if (isOpen) {
        setSelected((selected) => (selected + 1) % combined.length)
      }
    },
    shortcutsOptions,
  )
  useKeyboardShortcut(
    ['Enter'],
    () => {
      if (isOpen) {
        navigate(combined[selected]?.link)
        onClose()
      }
    },
    shortcutsOptions,
  )

  return (
    <>
      <Input
        ref={(input) => input?.focus()}
        size="lg"
        width="100%"
        value={value}
        onChange={({ target: { value } }) => setValue(value)}
      />

      <ResultSection
        title="Actions"
        results={actions}
        selectedKey={selectedKey}
        useCard={useCard}
        renderItem={(action) => <>{action.name}</>}
      />
      <ResultSection
        title="Accounts"
        results={accounts}
        selectedKey={selectedKey}
        useCard={useCard}
        renderItem={(account) => (
          <>
            <ResultSection.Title>
              <Flex justifyContent="space-between" alignItems="center">
                {account.address?.companyName ? (
                  <span>{account.address?.companyName}</span>
                ) : (
                  <span>
                    {account.address?.firstName} {account.address?.lastName}
                  </span>
                )}
              </Flex>
            </ResultSection.Title>
            <ResultSection.Description>
              {account.id} · {account.email}
            </ResultSection.Description>
          </>
        )}
      />
      <ResultSection
        title="Orders"
        results={orders}
        selectedKey={selectedKey}
        useCard={useCard}
        renderItem={(order) => (
          <>
            <ResultSection.Title>
              #{order.simpleId} {order.type}
            </ResultSection.Title>
            <ResultSection.Description>
              {sentenceCase(order.state)}
              {' · '}
              {order.orderDetails.deliveryDate}{' '}
              {formatTimeSlot(order.orderDetails.deliveryTimeSlot)}
              {' · '}
              <Account id={order.accountId} />
            </ResultSection.Description>
          </>
        )}
      />
      <ResultSection
        title="Items"
        results={items}
        selectedKey={selectedKey}
        useCard={useCard}
        renderItem={(item) => (
          <>
            <ResultSection.Title>
              #{item.simpleId} {item.name}
            </ResultSection.Title>
            <ResultSection.Description>
              {item.storageProduct.categoryId && (
                <>
                  <ProductCategory categoryId={item.storageProduct.categoryId} /> {': '}
                </>
              )}
              <Product productId={item.storageProduct.id} accountId={item.ownerId} />
              {' · '}
              {sentenceCase(item.state)}
              {' · '}
              <Account id={item.ownerId} />
            </ResultSection.Description>
          </>
        )}
      />
      <ResultSection
        title="ServiceOrders"
        results={serviceOrders}
        selectedKey={selectedKey}
        useCard={useCard}
        renderItem={(serviceOrder) => (
          <>
            <ResultSection.Title>#{serviceOrder.simpleId}</ResultSection.Title>
            <ResultSection.Description>
              {sentenceCase(serviceOrder.state)}
              {' · '}
              <Account id={serviceOrder.accountId} />
            </ResultSection.Description>
          </>
        )}
      />
      <ResultSection
        title="Listings"
        results={listings}
        selectedKey={selectedKey}
        useCard={useCard}
        renderItem={(listing) => (
          <>
            <ResultSection.Title>
              #{listing.simpleId} {listing.name}
            </ResultSection.Title>
            <ResultSection.Description>
              {sentenceCase(listing.status)}
              {' · '}
              <Account id={listing.sellerAccountId} />
            </ResultSection.Description>
          </>
        )}
      />
      <ResultSection
        title="Users"
        results={users}
        selectedKey={selectedKey}
        useCard={useCard}
        renderItem={(user) => (
          <>
            <ResultSection.Title>
              <Flex justifyContent="space-between" alignItems="center">
                <span>
                  {user.firstName} {user.lastName}
                </span>
              </Flex>
            </ResultSection.Title>
            <ResultSection.Description>
              {user.id} · {user.email} · <UserType userId={user.auth0Id} />
            </ResultSection.Description>
          </>
        )}
      />
    </>
  )
}
