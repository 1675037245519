import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@tanstack/react-query'
import { groupBy, indexBy, prop } from 'ramda'

import { type Product, getProducts } from '../api-client/routes/products'
import type { StorageItemType } from '../consts/enums'

export function useProducts(
  filters: Partial<Record<keyof Product | string, string[] | string | undefined | boolean>>,
  opts?: { suspense: boolean; enabled?: boolean },
) {
  const { getAccessTokenSilently } = useAuth0()
  return useQuery(
    ['products', filters],
    async () => {
      const token = await getAccessTokenSilently()
      const products = await getProducts({
        orderBy: 'sortOrder',
        orderDir: 'ASC',
        itemsPerPage: 1000,
        page: 0,
        search: undefined,
        filters: { active: true, ...filters },
        token,
      })
      return {
        products: products.items,
        byProductType: groupBy(prop('productType'), products.items),
        baseStorageProducts: indexBy(
          (p) => p.metadata.itemType as StorageItemType,
          products.items.filter((p) => p.metadata.baseStorageProduct),
        ),
        byCategoryId: groupBy(
          ({ categoryId }) => categoryId!,
          products.items.filter((p) => p.categoryId),
        ),
      }
    },
    { enabled: !!filters.country, ...opts },
  )
}
