import { useAuth0 } from '@auth0/auth0-react'
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@chakra-ui/react'
import { setUser } from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Loader } from '@wanda/shared/src/components/Loader'
import { useFeatureFlags } from '@wanda/shared/src/hooks/useFeatureFlags'
import { useRoles } from '@wanda/shared/src/hooks/useRoles'
import { values } from 'ramda'
import React, { Suspense, useEffect, useState } from 'react'
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom'

import { CappingOpsManagerProvider } from 'components/OpsCapping/cappingOpsContext'
import { SearchModal } from 'components/Search/SearchModal'
import { CenterProgress } from 'components/ui/CenterProgress/CenterProgress'
import { AppRoutes, createRouteFilter } from 'consts/Routes'

import { NotDefaultAccountModal, UserMissingCityModal } from '@wanda/shared'
import { IntlProvider } from './IntlProvider'
import { Layout } from './Layout/Layout'
import { Root } from './styles'

const { Login, Labels, ...RoutesMap } = AppRoutes

function RequireAuth() {
  const { isLoading, isAuthenticated, logout, error } = useAuth0()
  const location = useLocation()
  if (isLoading) {
    return <Loader />
  }
  if (error) {
    return (
      <Modal isOpen={true} onClose={() => logout()}>
        <ModalContent>
          <ModalHeader id="alert-dialog-title">Authentication error</ModalHeader>
          <ModalBody>{error.message}</ModalBody>
          <ModalFooter>
            <Button onClick={() => logout()}>Logout</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }
  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />
  }

  return <Outlet />
}

const Router = ({ setShowSearchModal }: { setShowSearchModal: (value: boolean) => void }) => {
  const { featureFlags, isInitialLoading, isError, error } = useFeatureFlags()
  const roles = useRoles()
  if (isInitialLoading) {
    return null
  }
  if (isError) {
    throw error
  }
  return (
    <CappingOpsManagerProvider>
      <Suspense fallback={<CenterProgress size="3rem" />}>
        <Routes>
          <Route element={<Login.Component />} path={Login.path} />
          <Route element={<Labels.Component />} path={Labels.path} />
          <Route element={<Layout setShowSearchModal={setShowSearchModal} />}>
            {values(RoutesMap)
              .filter(createRouteFilter({ featureFlags, roles }))
              .map(({ name, Component, ...route }) =>
                route.withAuth ? (
                  <Route key={name} element={<RequireAuth />}>
                    <Route
                      element={
                        <Suspense fallback={<CenterProgress size="3rem" />}>
                          <Component />
                        </Suspense>
                      }
                      path={route.path}
                    />
                  </Route>
                ) : (
                  <Route
                    key={name}
                    element={
                      <Suspense fallback={<CenterProgress size="3rem" />}>
                        <Component />
                      </Suspense>
                    }
                    path={route.path}
                  />
                ),
              )}
            <Route path="*" element={<Navigate to={AppRoutes.Dashboard.path} />} />
          </Route>
        </Routes>
      </Suspense>
    </CappingOpsManagerProvider>
  )
}

const App = () => {
  const [showSearchModal, setShowSearchModal] = useState(false)
  const { isLoading, error, logout, user } = useAuth0()
  const queryClient = new QueryClient({
    defaultOptions: { queries: { staleTime: 1000 * 60 } },
  })

  useEffect(() => {
    if (user) {
      setUser({ id: user['http://id.wanda.space/wanda_id'], email: user.email })
    }
  }, [user])

  if (error) {
    logout()
    throw error
  }

  return (
    <Root>
      <QueryClientProvider client={queryClient}>
        {isLoading ? (
          <CenterProgress size="3rem" />
        ) : (
          <Suspense fallback={<CenterProgress size="3rem" />}>
            <IntlProvider locale="en">
              <Router setShowSearchModal={setShowSearchModal} />
              <SearchModal
                isOpen={showSearchModal}
                onOpen={() => setShowSearchModal(true)}
                onClose={() => setShowSearchModal(false)}
              />
              <UserMissingCityModal />
              <NotDefaultAccountModal />
            </IntlProvider>
          </Suspense>
        )}
      </QueryClientProvider>
    </Root>
  )
}

export default App
