import React from 'react'

import { useAccount } from '../hooks/useAccount'

export function Account({
  id,
  showId,
  showName = true,
  title,
}: {
  id: string
  showId?: boolean
  showName?: boolean
  title?: string
}) {
  const { data: user, isSuccess } = useAccount(id, { enabled: showName, refetchOnMount: false })
  let text = id
  if (isSuccess) {
    let name = ''
    if (showName) {
      if (user.address?.firstName || user.address?.lastName) {
        name = `${user.address.firstName || ''} ${user.address.lastName || ''}`
      } else {
        name = user.email
      }
    }
    if (showId && showName) {
      text = `${name} (${id})`
    } else if (showName) {
      text = `${name}`
    }
  }
  return <span title={title}>{text}</span>
}
