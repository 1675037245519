import { Auth0Provider } from '@auth0/auth0-react'
import {
  Button,
  ChakraProvider,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react'
import { ExtraErrorData } from '@sentry/integrations'
import * as Sentry from '@sentry/react'
import { Integrations as TracingIntegrations } from '@sentry/tracing'
import { GeneralError } from '@wanda-space/noelle'
import { ApiError, getEnv, theme } from '@wanda/shared'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'

import App from 'components/App/App'

const environment =
  {
    'admin.dev.wanda.space': 'dev',
    'admin.wanda.space': 'production',
  }[window.location.hostname] || 'unknown'

Sentry.init({
  dsn: 'https://7ac441396fc4475391d096a24273556f@o987876.ingest.sentry.io/6013708',
  integrations: [new TracingIntegrations.BrowserTracing(), new ExtraErrorData()],
  environment,
  release: VERSION,
  enabled: ['dev', 'staging', 'production'].includes(environment),
  tracesSampleRate: 0.1,
  beforeSend(event, hint?) {
    if (hint?.originalException instanceof ApiError && hint.originalException.status() < 500) {
      return null
    }
    if (hint?.originalException?.toString().includes('ChunkLoadError')) {
      event.fingerprint = ['ChunkLoadError']
    }
    return event
  },
})

const domain = getEnv('AUTH0_DOMAIN')
const clientId = getEnv('AUTH0_CLIENT_ID')
const audience = getEnv('AUTH0_AUDIENCE')

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)

root.render(
  <Router>
    <Sentry.ErrorBoundary
      fallback={() => <GeneralError buttonText="Reload" onClick={() => window.location.reload()} />}
    >
      <ChakraProvider theme={theme}>
        <Sentry.ErrorBoundary
          fallback={({ error }) => {
            // eslint-disable-next-line no-console
            console.log(error, error.toString())
            return error.toString().includes('ChunkLoadError') ? (
              <Modal isOpen onClose={() => {}}>
                <ModalContent>
                  <ModalHeader>New version 🎉</ModalHeader>
                  <ModalBody>
                    Looks like we have a new version of admin, in order to use it you have to
                    reload.
                  </ModalBody>
                  <ModalFooter>
                    <Button onClick={() => window.location.reload()}>Reload</Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            ) : (
              <Modal isOpen onClose={() => {}}>
                <ModalContent>
                  <ModalHeader id="alert-dialog-title">Unrecoverable error</ModalHeader>
                  <ModalBody>Ops, something bad happened. Try opening admin again.</ModalBody>
                  <ModalFooter>
                    <Button onClick={() => window.location.replace('/')}>Reload</Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            )
          }}
        >
          <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
              audience,
              redirectUri: `${window.location.protocol}//${window.location.host}/login/callback`,
              prompt: 'select_account',
            }}
          >
            <App />
          </Auth0Provider>
        </Sentry.ErrorBoundary>
      </ChakraProvider>
    </Sentry.ErrorBoundary>
  </Router>,
)
