import React from 'react'

export function selectOptions(_enum: any, showEmptyOption = true) {
  return (
    <>
      {showEmptyOption && <option value="">---</option>}
      {(Object.keys(_enum) as Array<keyof typeof _enum>).map((key) => (
        <option key={key as string} value={_enum[key]}>
          {_enum[key]}
        </option>
      ))}
    </>
  )
}
