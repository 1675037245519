import { CircularProgress, type CircularProgressProps } from '@chakra-ui/react'
import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'

export const Center = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`

export const CenterProgress = (props: CircularProgressProps) => {
  const [show, setShow] = useState(false)
  useEffect(() => {
    const id = setTimeout(() => {
      setShow(true)
    }, 250)
    return () => id && clearTimeout(id)
  }, [])
  if (!show) return null
  return (
    <Center>
      <CircularProgress {...props} isIndeterminate color="purple.500" size="100px" />
    </Center>
  )
}
