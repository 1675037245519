import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'
import { Navigate } from 'react-router-dom'

export const Login = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0()

  if (isAuthenticated) {
    return <Navigate to="/" />
  }

  loginWithRedirect()

  return null
}

export const LoginCallback = () => {
  return <Navigate to={localStorage.lastRoute ?? '/dashboard'} />
}
