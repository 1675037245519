import { Box, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import type { UseQueryResult } from '@tanstack/react-query'
import React, { type ReactNode } from 'react'

import { Card } from 'components/ui/Card'

export function ResultSection<T extends { key: string; link: string }>({
  title,
  results,
  selectedKey,
  renderItem,
  useCard = false,
}: {
  title: string
  results: UseQueryResult<T[]>
  selectedKey: string
  useCard?: boolean
  renderItem: (item: T) => ReactNode
}) {
  const background = useColorModeValue('gray.100', 'gray.600')
  const hoverBackground = useColorModeValue('purple.100', 'purple.800')
  const activeBackground = useColorModeValue('purple.300', 'purple.700')
  if (!results.data || results.data?.length === 0) {
    return null
  }
  const Component = useCard ? Card : Box
  return (
    <Component pt="2" pb="2" mt="4">
      <Box fontSize="large" fontWeight="semibold" border="2px solid gray.200" mb="2">
        {title}
      </Box>
      <Stack>
        {results.data.map((item) => (
          <Box
            as="a"
            display="block"
            href={item.link}
            key={item.key}
            p="2"
            background={item.key === selectedKey ? activeBackground : background}
            borderRadius="8px"
            _hover={{ background: hoverBackground }}
            _focus={{ background: activeBackground }}
            _active={{ background: activeBackground }}
          >
            {renderItem(item)}
          </Box>
        ))}
      </Stack>
    </Component>
  )
}

ResultSection.Title = function ResultSectionTitle({ children }: { children: ReactNode }) {
  return <Text fontSize="medium">{children}</Text>
}

ResultSection.Description = function ResultSectionDescription({
  children,
}: {
  children: ReactNode
}) {
  return (
    <Text fontSize="small" fontWeight="light">
      {children}
    </Text>
  )
}
