import type { ItemImageResponseDto, PaginatedResponseDto } from '@wanda-space/types'
import type {
  Currency,
  ProductPriceType,
  ProductTarget,
  ProductType,
  ProductVisibility,
  SupportedCities,
  SupportedCountries,
} from '../../consts/enums'
import { request } from '../api-client'
import { type ApiFilters, fetchChunk } from '../chunk'
import type { OrderLineInput } from '../types'
import type { NewItem } from './orders'

export interface Product {
  id: string
  createdAt: string
  updatedAt: string
  name: string
  active: boolean
  priceType: ProductPriceType
  productType: ProductType
  metadata: Record<string, string | number | boolean | undefined>
  target: ProductTarget
  localizationKey: string
  categoryId: string
  country: SupportedCountries
  price: number
  currency: Currency
  supportedInCities: SupportedCities[]
  sortOrder: number
  applicableToProducts?: string[] | null
  iconName?: string | null
  productVisibility: ProductVisibility
  tags: string[]
  taxRate: number
  load?: number
}

export interface CreateProduct {
  name: string
  active: boolean
  price: number
  currency: string
  priceType: ProductPriceType
  productType: ProductType
  target: ProductTarget
  description?: string
  categoryId?: string
  localizationKey: string
  country: SupportedCountries
  metadata: Record<string, string | number | boolean | undefined>
  supportedInCities: SupportedCities[]
  sortOrder: number
  applicableToProducts?: string[] | null
}

export async function getProducts(params: {
  orderBy: string
  orderDir: 'ASC' | 'DESC'
  itemsPerPage: number
  page: number
  search: string | undefined
  filters: ApiFilters | undefined
  token: string
}): Promise<{ count: number; items: Product[] }> {
  return fetchChunk({ ...params, search: params.search?.trim(), url: '/admin/products' })
}

export async function getProduct(id: string, token: string) {
  const response = await getProducts({
    orderBy: 'id',
    orderDir: 'ASC',
    itemsPerPage: 1,
    page: 0,
    search: undefined,
    filters: { id },
    token,
  })
  return response.items[0]
}

export async function createProduct(values: CreateProduct, token: string): Promise<Product> {
  return await request({
    url: '/admin/products/',
    method: 'POST',
    token,
    body: values,
  })
}

export async function updateProduct(id: string, values: Partial<Product>, token: string) {
  return await request({
    url: `/admin/products/${id}`,
    method: 'PUT',
    token,
    body: values,
  })
}

export async function deleteProduct(id: string, token: string) {
  return await request({
    url: `/admin/products/${id}`,
    method: 'DELETE',
    token,
  })
}

export interface PackingQuoteRequest {
  storageItems: NewItem[]
  country: SupportedCountries
}

export interface PackingQuoteResponse {
  price: number
  currency: string
  orderlines: OrderLineInput[]
}

export async function fetchPackingQuote(
  { country, storageItems }: PackingQuoteRequest,
  token: string,
): Promise<PackingQuoteResponse> {
  return request<PackingQuoteResponse>({
    method: 'POST',
    url: '/product/packing/quote',
    body: { country, items: storageItems },
    token,
  })
}

export async function uploadProductImage(
  id: string,
  form: FormData,
  token: string,
): Promise<{ imageId: string }> {
  return request({
    method: 'POST',
    url: `admin/products/${id}/image`,
    body: form,
    token,
  })
}

export async function deleteProductImage(id: string, imageId: string, token: string) {
  return request({
    method: 'DELETE',
    url: `admin/products/${id}/image/${imageId}`,
    token,
  })
}

export async function fetchProductImageDetails(id: string, token: string) {
  return request<PaginatedResponseDto<ItemImageResponseDto>>({
    method: 'GET',
    url: `product/${id}/image-details`,
    token,
  })
}

export async function fetchProductImageDetail(id: string, imageId: string, token: string) {
  return request<ItemImageResponseDto>({
    method: 'GET',
    url: `product/${id}/image-detail/${imageId}`,
    token,
  })
}
