import { format } from 'date-fns'

import { ShortDateFormat } from '../consts/DateTimeFormats'

export function formatDate(date: string | undefined): string {
  if (!date) return ''
  const d = new Date(date)

  return format(d, ShortDateFormat)
}

export function formatTimeSlot(timeslot: { from: string; to: string } | undefined) {
  if (!timeslot) {
    return ''
  }

  return `${formatTime(timeslot.from)} - ${formatTime(timeslot.to)}`
}

function formatTime(time: string) {
  return `${time.substring(0, 2)}:${time.substring(2, 4)}`
}
