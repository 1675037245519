import type { FeatureFlags } from '@wanda/shared/src/hooks/useFeatureFlags'
import { UserRole } from '@wanda/shared/src/hooks/useRoles'
import { sortBy } from 'ramda'
import React from 'react'
import { type ComponentType, lazy } from 'react'
import { FiBox, FiPackage } from 'react-icons/fi'
import {
  MdAddBox as AddBox,
  MdBuild as Build,
  MdShoppingBasket as Buy,
  MdCamera as Camera,
  MdCardGiftcard as CardGiftcard,
  MdDashboard as DashboardIcon,
  MdEmojiEvents as EmojiEvents,
  MdEuro as Euro,
  MdFlag as Flag,
  MdFlightTakeoff as FlightTakeoff,
  MdLocalHospital as Hospital,
  MdImportExport as ImportExport,
  MdList as List,
  MdMarkunreadMailbox as MarkunreadMailbox,
  MdAccountCircle,
  MdAlarm,
  MdCategory,
  MdDiscount,
  MdDriveEta,
  MdFireTruck,
  MdHome,
  MdHourglassEmpty,
  MdLanguage,
  MdLoyalty,
  MdManageAccounts,
  MdMap,
  MdRefresh,
  MdRoute,
  MdSearch,
  MdSearchOff,
  MdShelves,
  MdWarehouse,
  MdStorage as Storage,
  MdSync as Sync,
  MdVerifiedUser as VerifiedUser,
} from 'react-icons/md'

import { Login, LoginCallback } from 'components/Login/Login'
import RefreshCapacity from 'components/RefreshCapacity/RefreshCapacity'
import { Navigate } from 'react-router-dom'
import { withRequiredParams } from 'withRequiredParam'

const Search = lazy(() => import('components/Search/SearchPage'))
const LocationsList = lazy(() => import('components/Warehouses/LocationsList'))
const LocationDetails = lazy(() => import('components/Warehouses/LocationDetails'))
const WarehousesList = lazy(() => import('components/Warehouses/WarehousesList'))
const BateExport = lazy(() => import('components/LoyaltyMemberships/BateExport'))
const RouteList = lazy(() => import('components/Routes/RouteList'))
const RouteStops = lazy(() => import('components/Routes/RouteStops'))
const PostalCodeZonesList = lazy(() => import('components/PostalCodes/PostalCodeZonesList'))
const RoutePlanningList = lazy(() => import('components/RoutePlanning/RoutePlannningList'))
const RoutePlanningView = lazy(() => import('components/RoutePlanning/RoutePlanningView'))
const EditServiceProvider = lazy(() => import('components/ServiceProviders/EditServiceProvider'))
const CreateServiceProvider = lazy(
  () => import('../components/ServiceProviders/CreateServiceProvider'),
)
const Dashboard = lazy(() => import('components/Dashboard/Dashboard'))
const OrderList = lazy(() => import('components/Orders/OrderList'))
const UnclosedOrderList = lazy(() => import('components/Orders/UnclosedOrderList'))
const OrderDetails = lazy(() => import('components/Orders/OrderDetails'))
const ItemList = lazy(() => import('components/Items/ItemList'))
const ItemsMissingLocation = lazy(() => import('components/Items/ItemsMissingLocation'))
const OpsItemList = lazy(() => import('components/Items/OpsItemList'))
const UserList = lazy(() => import('components/Users/UserList'))
const UserDetails = lazy(() => import('components/Users/UserDetails'))
const HandoverList = lazy(() => import('components/Handovers/HandoverList'))
const ItemsReport = lazy(() => import('components/ItemsReport/ItemsReport'))
const PackagingOrderList = lazy(() => import('components/PackagingOrders/PackagingOrderList'))
const PackagingOrdersExport = lazy(() => import('components/PackagingOrders/PackagingOrdersExport'))
const LoyaltyMembershipList = lazy(() => import('components/LoyaltyMemberships'))
const BackgroundJobList = lazy(() => import('components/BackgroundJobs/BackgroundJobList'))
const CreateBackgroundJob = lazy(() => import('components/BackgroundJobs/CreateJob'))
const FeatureFlagsList = lazy(() => import('components/FeatureFlags/FeatureFlagsList'))
const PostalCodesList = lazy(() => import('components/PostalCodes/PostalCodesList'))
const CreateOrder = lazy(() => import('components/CreateOrder/CreateOrder'))
const CreateItem = lazy(() => import('components/CreateItem/CreateItem'))
const ProductsList = lazy(() => import('components/Products/ProductsList'))
const ServiceProviderList = lazy(() => import('components/ServiceProviders/ServiceProviderList'))
const ServiceProviderImageView = lazy(
  () => import('components/ServiceProviders/ServiceProviderImageView'),
)
const CreateProduct = lazy(() => import('components/Products/CreateProduct'))
const EditProduct = lazy(() => import('components/Products/EditProduct'))
const ProductCategoriesList = lazy(
  () => import('components/ProductCategories/ProductCategoriesList'),
)
const CreateProductCategory = lazy(
  () => import('components/ProductCategories/CreateProductCategory'),
)
const EditProductCategory = lazy(() => import('components/ProductCategories/EditProductCategory'))

const ServiceOrderList = lazy(() => import('components/ServiceOrders/ServiceOrderList'))
const ServiceOrderDetails = lazy(() => import('components/ServiceOrders/ServiceOrderDetails'))
const CreateServiceOrder = lazy(() => import('components/ServiceOrders/CreateServiceOrder'))
const Listing = lazy(() => import('components/Listing/Listing'))
const CreateListing = lazy(() => import('components/Listing/CreateListing'))
const ListingDetails = lazy(() => import('components/Listing/ListingDetails'))
const Labels = lazy(() => import('components/Labels/LabelRoutes'))
const OpsCapping = lazy(() => import('components/OpsCapping'))
const PriceGroupList = lazy(() => import('components/PriceGroups'))
const CreatePriceGroup = lazy(() => import('components/PriceGroups/CreatePriceGroup'))
const Discount = lazy(() => import('components/Discounts/DiscountList'))
const ConsignmentList = lazy(() => import('components/Items/ConsignmentList'))
const TranslationList = lazy(() => import('components/Translations/TranslationList'))
const AccountList = lazy(() => import('components/Accounts/AccountList'))
const AccountDetails = lazy(() => import('components/Accounts/AccountDetails'))
const WarehouseOrders = lazy(() => import('components/Warehouses/WarehouseOrders'))

export type RouteType = {
  path: string
  withAuth?: boolean
  exact?: boolean
  name: string
  featureFlag?: FeatureFlags
  routes?: () => RouteType[]
  /* should require one of the roles in the list */
  requireRoles: UserRole[]
  Icon?: ComponentType<any>
  Component: ComponentType<any>
}

export const createRouteFilter =
  ({
    roles,
    featureFlags,
  }: {
    roles: UserRole[]
    featureFlags: Record<string, boolean | undefined>
  }) =>
  ({ featureFlag, requireRoles }: RouteType) => {
    const flagCheck = !featureFlag || featureFlags?.[featureFlag]
    const roleCheck =
      !requireRoles ||
      requireRoles.length === 0 ||
      roles.some((role) => requireRoles.includes(role))
    return flagCheck && roleCheck
  }

export const AppRoutes: { [key: string]: RouteType } = {
  Dashboard: {
    path: '/dashboard',
    withAuth: true,
    exact: true,
    name: 'Dashboard',
    Component: Dashboard,
    Icon: DashboardIcon,
    requireRoles: [],
  },
  Login: {
    path: '/login',
    exact: true,
    name: 'Login',
    Component: Login,
    requireRoles: [],
  },
  LoginCallback: {
    path: '/login/callback',
    exact: true,
    name: 'LoginCallback',
    Component: LoginCallback,
    requireRoles: [],
  },
  CreateOrder: {
    path: '/Orders/Create',
    withAuth: true,
    exact: true,
    name: 'Create Order',
    Component: CreateOrder,
    Icon: AddBox,
    requireRoles: [UserRole.ADMIN, UserRole.CUSTOMER_SUPPORT],
  },
  Orders: {
    path: '/Orders',
    withAuth: true,
    exact: true,
    name: 'Orders',
    Component: OrderList,
    Icon: FlightTakeoff,
    routes: () => [AppRoutes.CreateOrder, AppRoutes.UnclosedOrders],
    requireRoles: [
      UserRole.ADMIN,
      UserRole.CUSTOMER_SUPPORT,
      UserRole.OPS_ADMIN,
      UserRole.OPS_OPERATOR,
      UserRole.OPS_WAREHOUSE,
      UserRole.OPS_INTERNAL_DRIVER,
    ],
  },
  UnclosedOrders: {
    path: '/Orders/unclosed',
    withAuth: true,
    exact: true,
    name: 'Unclosed Orders',
    Component: UnclosedOrderList,
    Icon: MdAlarm,
    requireRoles: [UserRole.ADMIN, UserRole.CUSTOMER_SUPPORT],
  },
  OrderDetails: {
    path: '/Orders/:id',
    withAuth: true,
    exact: true,
    name: 'OrderDetails',
    Component: OrderDetails,
    Icon: FlightTakeoff,
    requireRoles: [UserRole.ADMIN, UserRole.CUSTOMER_SUPPORT],
  },
  CreateItem: {
    path: '/Items/Create',
    withAuth: true,
    exact: true,
    name: 'Create Item',
    Component: CreateItem,
    Icon: AddBox,
    requireRoles: [UserRole.ADMIN, UserRole.CUSTOMER_SUPPORT],
  },
  Items: {
    path: '/Items',
    withAuth: true,
    exact: true,
    name: 'Items',
    Component: ItemList,
    Icon: Storage,
    routes: () => [
      AppRoutes.CreateItem,
      AppRoutes.ItemOpsList,
      AppRoutes.ItemMissingLocation,
      AppRoutes.ItemsReport,
      AppRoutes.ConsignmentList,
    ],
    requireRoles: [
      UserRole.ADMIN,
      UserRole.CUSTOMER_SUPPORT,
      UserRole.OPS_ADMIN,
      UserRole.OPS_OPERATOR,
      UserRole.OPS_WAREHOUSE,
      UserRole.OPS_INTERNAL_DRIVER,
    ],
  },
  ItemMissingLocation: {
    path: '/items/missing-location',
    withAuth: true,
    exact: true,
    name: 'Items missing location',
    Component: ItemsMissingLocation,
    Icon: MdSearchOff,
    featureFlag: 'ENABLE_ADMIN_ITEMS_MISSING_LOCATION',
    requireRoles: [
      UserRole.ADMIN,
      UserRole.CUSTOMER_SUPPORT,
      UserRole.OPS_ADMIN,
      UserRole.OPS_OPERATOR,
      UserRole.OPS_WAREHOUSE,
      UserRole.OPS_INTERNAL_DRIVER,
    ],
  },
  ItemOpsList: {
    path: '/items/wms',
    withAuth: true,
    exact: true,
    name: 'WMS',
    Component: OpsItemList,
    Icon: FiPackage,
    requireRoles: [
      UserRole.ADMIN,
      UserRole.CUSTOMER_SUPPORT,
      UserRole.OPS_ADMIN,
      UserRole.OPS_OPERATOR,
      UserRole.OPS_WAREHOUSE,
      UserRole.OPS_INTERNAL_DRIVER,
    ],
  },
  ConsignmentList: {
    path: '/items/consignments',
    withAuth: true,
    exact: true,
    name: 'Consignments',
    Component: ConsignmentList,
    Icon: FiBox,
    requireRoles: [
      UserRole.ADMIN,
      UserRole.CUSTOMER_SUPPORT,
      UserRole.OPS_ADMIN,
      UserRole.OPS_OPERATOR,
      UserRole.OPS_WAREHOUSE,
      UserRole.OPS_INTERNAL_DRIVER,
    ],
  },

  UsersDetails: {
    path: '/accounts/users/:id',
    withAuth: true,
    exact: true,
    name: 'Users',
    Component: UserDetails,
    Icon: VerifiedUser,
    requireRoles: [UserRole.ADMIN, UserRole.CUSTOMER_SUPPORT],
  },
  Users: {
    path: '/accounts/users',
    withAuth: true,
    exact: true,
    name: 'Users',
    Component: UserList,
    Icon: VerifiedUser,
    routes: () => [],
    requireRoles: [UserRole.ADMIN, UserRole.CUSTOMER_SUPPORT],
  },
  UsersRedirect: {
    path: '/Users',
    name: 'UsersRedirect',
    requireRoles: [UserRole.ADMIN, UserRole.CUSTOMER_SUPPORT],
    Component: () => <Navigate to="/accounts/users" />,
  },
  UsersDetailsRedirect: {
    path: '/Users/:id',
    name: 'UsersRedirect',
    requireRoles: [UserRole.ADMIN, UserRole.CUSTOMER_SUPPORT],
    Component: withRequiredParams(['id'], (props) => {
      console.log(props)
      return <Navigate to={`/accounts/users/${props.id}`} />
    }),
  },
  Handovers: {
    path: '/Handovers',
    withAuth: true,
    exact: true,
    name: 'Handovers',
    Component: HandoverList,
    Icon: List,
    requireRoles: [UserRole.TECH],
  },
  ItemsReport: {
    path: '/ItemsReport',
    withAuth: true,
    exact: true,
    name: 'Items Report',
    Component: ItemsReport,
    Icon: Storage,
    requireRoles: [UserRole.ADMIN, UserRole.CUSTOMER_SUPPORT],
  },
  BateExport: {
    path: '/LoyaltyMemberships/exports/bate',
    withAuth: true,
    exact: true,
    name: 'Bate export',
    Component: BateExport,
    Icon: ImportExport,
    requireRoles: [UserRole.ADMIN, UserRole.CUSTOMER_SUPPORT],
  },
  LoyaltyMemberships: {
    path: '/LoyaltyMemberships',
    withAuth: true,
    exact: true,
    name: 'Loyalty Memberships',
    Component: LoyaltyMembershipList,
    Icon: EmojiEvents,
    requireRoles: [UserRole.ADMIN, UserRole.CUSTOMER_SUPPORT],
  },
  PackagingOrders: {
    path: '/PackagingOrders',
    withAuth: true,
    exact: true,
    name: 'Packaging Orders',
    Component: PackagingOrderList,
    Icon: CardGiftcard,
    routes: () => [AppRoutes.PackagingOrdersExport],
    requireRoles: [UserRole.ADMIN, UserRole.CUSTOMER_SUPPORT],
  },
  PackagingOrdersExport: {
    path: '/PackagingOrders/export',
    withAuth: true,
    exact: true,
    name: 'Export orders',
    Component: PackagingOrdersExport,
    Icon: ImportExport,
    requireRoles: [UserRole.ADMIN, UserRole.CUSTOMER_SUPPORT],
  },
  BackgroundJobs: {
    path: '/BackgroundJobs',
    withAuth: true,
    exact: true,
    name: 'Background Jobs',
    Component: BackgroundJobList,
    Icon: Sync,
    routes: () => [AppRoutes.CreateBackgroundJob],
    requireRoles: [UserRole.ADMIN],
  },
  CreateBackgroundJob: {
    path: '/BackgroundJobs/create',
    withAuth: true,
    exact: true,
    name: 'Create background job',
    Component: CreateBackgroundJob,
    Icon: AddBox,
    requireRoles: [UserRole.ADMIN],
  },
  FeatureFlags: {
    path: '/FeatureFlags',
    withAuth: true,
    exact: true,
    name: 'Feature flags',
    Component: FeatureFlagsList,
    Icon: Flag,
    requireRoles: [UserRole.ADMIN],
  },
  PostalCodes: {
    path: '/PostalCodes',
    withAuth: true,
    exact: true,
    name: 'Postal codes',
    Component: PostalCodesList,
    Icon: MarkunreadMailbox,
    requireRoles: [UserRole.ADMIN, UserRole.CUSTOMER_SUPPORT, UserRole.OPS_ADMIN],
    routes: () => [AppRoutes.PostalCodeZones],
  },
  PostalCodeZones: {
    path: '/PostalCodes/zones',
    withAuth: true,
    exact: true,
    name: 'Postal code zones',
    Component: PostalCodeZonesList,
    Icon: MdMap,
    requireRoles: [UserRole.ADMIN, UserRole.CUSTOMER_SUPPORT, UserRole.OPS_ADMIN],
  },
  CreatePriceGroup: {
    path: '/price-groups/create',
    exact: true,
    name: 'Create price group',
    Component: CreatePriceGroup,
    Icon: AddBox,
    requireRoles: [UserRole.ADMIN, UserRole.PRODUCT_ADMIN],
  },
  CreateProduct: {
    path: '/products/create',
    withAuth: true,
    exact: true,
    name: 'Create product',
    Component: CreateProduct,
    Icon: AddBox,
    requireRoles: [UserRole.ADMIN, UserRole.PRODUCT_ADMIN],
  },
  EditProduct: {
    path: '/products/:id',
    withAuth: true,
    exact: true,
    name: 'Edit product',
    Component: EditProduct,
    Icon: AddBox,
    requireRoles: [UserRole.ADMIN, UserRole.PRODUCT_ADMIN],
  },
  Products: {
    path: '/products',
    withAuth: true,
    exact: true,
    name: 'Products',
    Component: ProductsList,
    Icon: Euro,
    routes: () => [
      AppRoutes.CreateProduct,
      AppRoutes.ProductCategories,
      AppRoutes.CreateProductCategories,
    ],
    requireRoles: [UserRole.ADMIN, UserRole.PRODUCT_ADMIN],
  },
  Discount: {
    path: '/discounts',
    withAuth: true,
    exact: true,
    name: 'Discounts',
    Component: Discount,
    Icon: MdDiscount,
    requireRoles: [UserRole.ADMIN, UserRole.PRODUCT_ADMIN],
  },
  EditServiceProvider: {
    path: '/service-providers/:id',
    withAuth: true,
    exact: true,
    name: 'Edit Service Provider',
    Component: EditServiceProvider,
    Icon: AddBox,
    requireRoles: [UserRole.ADMIN, UserRole.CUSTOMER_SUPPORT],
  },
  ServiceProviders: {
    path: '/service-providers',
    withAuth: true,
    exact: true,
    name: 'Service Providers',
    Component: ServiceProviderList,
    routes: () => [AppRoutes.CreateServiceProvider, AppRoutes.ServiceProviderImages],
    Icon: Hospital,
    requireRoles: [UserRole.ADMIN, UserRole.CUSTOMER_SUPPORT, UserRole.PRODUCT_ADMIN],
  },
  ServiceProviderImages: {
    path: '/service-providers-images',
    withAuth: true,
    exact: true,
    name: 'Service Provider Images',
    Component: ServiceProviderImageView,
    Icon: Camera,
    requireRoles: [UserRole.ADMIN, UserRole.CUSTOMER_SUPPORT, UserRole.PRODUCT_ADMIN],
  },
  CreateServiceProvider: {
    path: '/service-providers/create',
    withAuth: true,
    exact: true,
    name: 'Create Service Provider',
    Component: CreateServiceProvider,
    Icon: AddBox,
    requireRoles: [UserRole.ADMIN, UserRole.CUSTOMER_SUPPORT, UserRole.PRODUCT_ADMIN],
  },
  CreateProductCategories: {
    path: '/products/categories/create',
    withAuth: true,
    exact: true,
    name: 'Create product category',
    Component: CreateProductCategory,
    Icon: AddBox,
    requireRoles: [UserRole.ADMIN, UserRole.PRODUCT_ADMIN],
  },
  EditProductCategories: {
    path: '/products/categories/:id',
    withAuth: true,
    exact: true,
    name: 'Edit product category',
    Component: EditProductCategory,
    Icon: AddBox,
    requireRoles: [UserRole.ADMIN, UserRole.PRODUCT_ADMIN],
  },
  ProductCategories: {
    path: '/products/categories',
    withAuth: true,
    exact: true,
    name: 'Product categories',
    Component: ProductCategoriesList,
    Icon: MdCategory,
    requireRoles: [UserRole.ADMIN, UserRole.CUSTOMER_SUPPORT],
  },
  ServiceOrderList: {
    path: '/service-orders',
    withAuth: true,
    exact: true,
    name: 'Service orders',
    Component: ServiceOrderList,
    Icon: Build,
    routes: () => [AppRoutes.CreateServiceOrder],
    requireRoles: [
      UserRole.ADMIN,
      UserRole.CUSTOMER_SUPPORT,
      UserRole.OPS_OPERATOR,
      UserRole.OPS_WAREHOUSE,
      UserRole.OPS_INTERNAL_DRIVER,
    ],
  },
  CreateServiceOrder: {
    path: '/service-orders/create',
    withAuth: true,
    exact: true,
    name: 'Create Service Order',
    Component: CreateServiceOrder,
    Icon: AddBox,
    featureFlag: 'ENABLE_ADMIN_CREATE_SERVICE_ORDERS',
    requireRoles: [
      UserRole.ADMIN,
      UserRole.CUSTOMER_SUPPORT,
      UserRole.OPS_OPERATOR,
      UserRole.OPS_WAREHOUSE,
      UserRole.OPS_INTERNAL_DRIVER,
    ],
  },
  ServiceOrderDetails: {
    path: '/service-orders/:id',
    withAuth: true,
    exact: true,
    name: 'Service orders',
    Component: ServiceOrderDetails,
    Icon: Build,
    requireRoles: [
      UserRole.ADMIN,
      UserRole.CUSTOMER_SUPPORT,
      UserRole.OPS_OPERATOR,
      UserRole.OPS_WAREHOUSE,
      UserRole.OPS_INTERNAL_DRIVER,
    ],
  },
  Listing: {
    path: '/listing',
    withAuth: true,
    exact: true,
    name: 'Listing',
    Component: Listing,
    Icon: Buy,
    routes: () => [AppRoutes.CreateListing],
    requireRoles: [
      UserRole.ADMIN,
      UserRole.CUSTOMER_SUPPORT,
      UserRole.OPS_OPERATOR,
      UserRole.OPS_WAREHOUSE,
      UserRole.OPS_INTERNAL_DRIVER,
    ],
  },
  ListingDetails: {
    path: '/listing/:id',
    withAuth: true,
    exact: true,
    name: 'Listing',
    Component: ListingDetails,
    Icon: Buy,
    requireRoles: [
      UserRole.ADMIN,
      UserRole.CUSTOMER_SUPPORT,
      UserRole.OPS_OPERATOR,
      UserRole.OPS_WAREHOUSE,
      UserRole.OPS_INTERNAL_DRIVER,
    ],
  },
  CreateListing: {
    path: '/listing/Create',
    withAuth: true,
    exact: true,
    name: 'Create listing',
    Component: CreateListing,
    Icon: MdLoyalty,
    requireRoles: [
      UserRole.ADMIN,
      UserRole.CUSTOMER_SUPPORT,
      UserRole.OPS_OPERATOR,
      UserRole.OPS_WAREHOUSE,
      UserRole.OPS_INTERNAL_DRIVER,
    ],
  },
  Labels: {
    path: '/labels/*',
    withAuth: true,
    exact: true,
    name: 'Labels',
    Component: Labels,
    Icon: Buy,
    requireRoles: [
      UserRole.ADMIN,
      UserRole.OPS_ADMIN,
      UserRole.OPS_OPERATOR,
      UserRole.OPS_WAREHOUSE,
      UserRole.OPS_INTERNAL_DRIVER,
      UserRole.CUSTOMER_SUPPORT,
    ],
  },
  SendSMS: {
    path: '/routes/route-stops',
    withAuth: true,
    exact: true,
    name: 'Route stops',
    Component: RouteStops,
    Icon: MdHome,
    featureFlag: 'ENABLE_ADMIN_ROUTES',
    requireRoles: [UserRole.ADMIN, UserRole.OPS_ADMIN, UserRole.CUSTOMER_SUPPORT],
  },
  RouteList: {
    path: '/routes',
    withAuth: true,
    exact: true,
    name: 'Routes',
    Component: RouteList,
    Icon: MdDriveEta,
    featureFlag: 'ENABLE_ADMIN_ROUTES',
    routes: () => [AppRoutes.RoutePlanningList, AppRoutes.SendSMS],
    requireRoles: [
      UserRole.ADMIN,
      UserRole.OPS_ADMIN,
      UserRole.OPS_OPERATOR,
      UserRole.OPS_WAREHOUSE,
      UserRole.OPS_INTERNAL_DRIVER,
      UserRole.CUSTOMER_SUPPORT,
    ],
  },
  RoutePlanningList: {
    path: '/planning/routes',
    withAuth: true,
    exact: true,
    name: 'Route planning',
    Component: RoutePlanningList,
    Icon: MdRoute,
    featureFlag: 'ENABLE_ADMIN_ROUTE_PLANNING',
    requireRoles: [UserRole.OPS_ADMIN],
  },
  RoutePlanningView: {
    path: '/planning/routes/:id',
    withAuth: true,
    exact: true,
    name: 'Route problem create',
    Component: RoutePlanningView,
    featureFlag: 'ENABLE_ADMIN_ROUTE_PLANNING',
    requireRoles: [UserRole.OPS_ADMIN],
  },
  OpsCapping: {
    path: '/timeslots',
    withAuth: true,
    exact: true,
    name: 'Ops capping',
    Component: OpsCapping,
    Icon: MdHourglassEmpty,
    requireRoles: [UserRole.ADMIN, UserRole.OPS_ADMIN, UserRole.CUSTOMER_SUPPORT],
    routes: () => [AppRoutes.RefreshCapacity],
  },
  RefreshCapacity: {
    path: '/refresh-capacity',
    withAuth: true,
    exact: true,
    name: 'Refresh capacity',
    Component: RefreshCapacity,
    Icon: MdRefresh,
    requireRoles: [UserRole.OPS_ADMIN],
  },
  PriceGroups: {
    path: '/price-groups',
    withAuth: true,
    exact: true,
    name: 'Price Groups',
    Component: PriceGroupList,
    Icon: Euro,
    routes: () => [AppRoutes.CreatePriceGroup],
    requireRoles: [UserRole.ADMIN, UserRole.PRODUCT_ADMIN],
  },
  Warehouses: {
    path: '/warehouses',
    withAuth: true,
    exact: true,
    name: 'Warehouses',
    Component: WarehousesList,
    Icon: MdWarehouse,
    requireRoles: [UserRole.ADMIN, UserRole.OPS_ADMIN],
    routes: () => [AppRoutes.Locations, AppRoutes.WarehouseOrders],
  },
  Locations: {
    path: '/warehouses/locations',
    withAuth: true,
    exact: true,
    name: 'Locations',
    Component: LocationsList,
    Icon: MdShelves,
    requireRoles: [UserRole.ADMIN, UserRole.OPS_ADMIN],
  },
  LocationDetails: {
    path: '/warehouses/locations/:id',
    withAuth: true,
    exact: true,
    name: 'Location Details',
    Component: LocationDetails,
    Icon: MdShelves,
    requireRoles: [UserRole.ADMIN, UserRole.OPS_ADMIN],
  },
  WarehouseOrders: {
    path: '/warehouses/order/create',
    withAuth: true,
    exact: true,
    name: 'Warehouse Orders',
    Component: WarehouseOrders,
    Icon: MdFireTruck,
    requireRoles: [UserRole.ADMIN, UserRole.OPS_ADMIN],
  },
  Search: {
    path: '/search',
    withAuth: true,
    exact: true,
    name: 'Locations',
    Component: Search,
    Icon: MdSearch,
    requireRoles: [UserRole.ADMIN, UserRole.OPS_ADMIN],
  },
  Translations: {
    path: '/translations',
    withAuth: true,
    exact: true,
    name: 'Translations',
    Component: TranslationList,
    Icon: MdLanguage,
    featureFlag: 'ENABLE_TRANSLATION_FROM_DB',
    requireRoles: [UserRole.ADMIN, UserRole.PRODUCT_ADMIN, UserRole.TECH],
  },
  Accounts: {
    path: '/accounts',
    withAuth: true,
    exact: true,
    name: 'Accounts',
    Component: AccountList,
    Icon: MdManageAccounts,
    featureFlag: 'ENABLE_ADMIN_ACCOUNTS_VIEW',
    requireRoles: [
      UserRole.ADMIN,
      UserRole.PRODUCT_ADMIN,
      UserRole.TECH,
      UserRole.CUSTOMER_SUPPORT,
    ],
    routes: () => [AppRoutes.Users, AppRoutes.LoyaltyMemberships, AppRoutes.BateExport],
  },
  AccountDetails: {
    path: '/accounts/:id',
    withAuth: true,
    exact: true,
    name: 'Account Details',
    Component: AccountDetails,
    Icon: MdAccountCircle,
    featureFlag: 'ENABLE_ADMIN_ACCOUNTS_VIEW',
    requireRoles: [
      UserRole.ADMIN,
      UserRole.PRODUCT_ADMIN,
      UserRole.TECH,
      UserRole.CUSTOMER_SUPPORT,
    ],
  },
}

export const RoutesMap = Object.entries(AppRoutes)
export const SidebarRoutes = [
  ...sortBy(
    (route) => route.name.replace('Create', '').trim(),
    [
      AppRoutes.Items,
      AppRoutes.Orders,
      AppRoutes.PackagingOrders,
      AppRoutes.BackgroundJobs,
      AppRoutes.FeatureFlags,
      AppRoutes.PostalCodes,
      AppRoutes.Products,
      AppRoutes.ServiceProviders,
      AppRoutes.Handovers,
      AppRoutes.ServiceOrderList,
      AppRoutes.Listing,
      AppRoutes.RouteList,
      AppRoutes.OpsCapping,
      AppRoutes.PriceGroups,
      AppRoutes.Discount,
      AppRoutes.Warehouses,
      AppRoutes.Translations,
      AppRoutes.Accounts,
    ],
  ),
]
