import React, { type ReactNode, useContext, useState } from 'react'

type Direction = 'horizontal' | 'vertical'

const SplitViewContext = React.createContext<{
  direction: Direction
  setDirection: (direction: Direction) => void
}>({ direction: 'vertical', setDirection: () => {} })

export function SplitViewProvider({ children }: { children: ReactNode }) {
  const [direction, setDirectionState] = useState<Direction>(
    localStorage.splitDirection || 'vertical',
  )
  function setDirection(value: Direction) {
    setDirectionState(value)
    localStorage.splitDirection = value
  }
  return (
    <SplitViewContext.Provider value={{ direction, setDirection }}>
      {children}
    </SplitViewContext.Provider>
  )
}

export function useSplitView() {
  return useContext(SplitViewContext)
}
