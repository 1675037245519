import { Tag, type TagProps } from '@chakra-ui/react'
import type { Auth0Id } from '@wanda-space/types'
import React from 'react'

type UserType = 'google-oauth2' | 'auth0' | 'sms' | 'oauth2'

function toUserType(type: string): UserType {
  if (['google-oauth2', 'auth0', 'sms', 'oauth2'].includes(type)) {
    return type as UserType
  }
  throw new Error(`Invalid user type ${type}`)
}

const colors: Record<UserType, string> = {
  oauth2: 'orange',
  'google-oauth2': 'blue',
  auth0: 'purple',
  sms: 'green',
}

export function UserType({
  size = 'sm',
  userId,
}: {
  size?: TagProps['size']
  userId: Auth0Id
}) {
  const userType = toUserType(userId.split('|')[0])
  return (
    <>
      <Tag
        ml={1}
        size={size}
        data-testid={`user-type-${userType}`}
        colorScheme={colors[userType]}
        variant="outline"
      >
        {userType}
      </Tag>
    </>
  )
}
