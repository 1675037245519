import { useQuery } from '@tanstack/react-query'
import { getTranslations } from '@wanda/shared/src/api-client/routes/localization'
import React, { type ReactNode } from 'react'
import { IntlProvider as ReactIntlProvider } from 'react-intl'

export function IntlProvider({
  locale = 'en',
  children,
}: {
  locale: 'en' | 'sv' | 'no'
  children: ReactNode
}) {
  const result = useQuery(
    ['translations', locale],
    async () => {
      return getTranslations(locale)
    },
    {
      suspense: true,
    },
  )
  return (
    <ReactIntlProvider
      messages={result.data}
      locale={locale}
      defaultLocale={locale}
      onWarn={() => {}}
      onError={() => {}}
    >
      {children}
    </ReactIntlProvider>
  )
}
