import { useAuth0 } from '@auth0/auth0-react'
import { Box, Flex, IconButton, Text, useColorMode } from '@chakra-ui/react'
import { useFeatureFlags } from '@wanda/shared/src/hooks/useFeatureFlags'
import React from 'react'
import {
  MdHorizontalSplit,
  MdLogout,
  MdMenu,
  MdNightsStay,
  MdSearch,
  MdVerticalSplit,
  MdWbSunny,
} from 'react-icons/md'
import { Link } from 'react-router-dom'

import { useSplitView } from 'components/ui/SplitView/SplitViewContext'

export const Header = function Header({
  onClickMenuIcon,
  onClickSearch,
}: {
  onClickMenuIcon: () => void
  onClickSearch: () => void
}) {
  const { logout } = useAuth0()
  const { direction, setDirection } = useSplitView()
  const { featureFlags } = useFeatureFlags()
  const { colorMode, setColorMode } = useColorMode()

  return (
    <Flex
      position="sticky"
      background="purple.500"
      color="white"
      justifyContent="space-between"
      alignItems="center"
      p="1"
      pt="2"
      pb="2"
    >
      <Flex alignItems="center">
        <IconButton
          fontSize="x-large"
          icon={<MdMenu />}
          aria-label="open drawer"
          color="inherit"
          background="transparent"
          onClick={onClickMenuIcon}
        />

        <Text as={Link} fontSize="x-large" fontWeight="semibold" to="/">
          Wanda Admin
        </Text>
      </Flex>
      <Box>
        {featureFlags?.ENABLE_ADMIN_SEARCH_MODAL && (
          <IconButton
            icon={<MdSearch />}
            aria-label="Search"
            color="inherit"
            fontSize="x-large"
            background="transparent"
            onClick={onClickSearch}
          />
        )}
        {direction === 'horizontal' ? (
          <IconButton
            icon={<MdVerticalSplit />}
            aria-label="Switch to vertical split"
            fontSize="x-large"
            color="inherit"
            background="transparent"
            onClick={() => {
              setDirection('vertical')
            }}
          />
        ) : (
          <IconButton
            icon={<MdHorizontalSplit />}
            aria-label="Switch to horizontal split"
            color="inherit"
            fontSize="x-large"
            background="transparent"
            onClick={() => {
              setDirection('horizontal')
            }}
          />
        )}
        {colorMode === 'dark' ? (
          <IconButton
            icon={<MdWbSunny />}
            aria-label="Switch to light mode"
            fontSize="x-large"
            color="inherit"
            background="transparent"
            onClick={() => {
              setColorMode('light')
            }}
          />
        ) : (
          <IconButton
            icon={<MdNightsStay />}
            aria-label="Switch to dark mode"
            color="inherit"
            fontSize="x-large"
            background="transparent"
            onClick={() => {
              setColorMode('dark')
            }}
          />
        )}
        <IconButton
          icon={<MdLogout />}
          aria-label="Logout"
          fontSize="x-large"
          color="inherit"
          background="transparent"
          onClick={() => {
            logout()
          }}
        />
      </Box>
    </Flex>
  )
}
