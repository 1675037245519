import { SupportedCities } from '@wanda/shared/src/consts/enums'
import React, { createContext, useState, useContext } from 'react'

interface CappingContextInterface {
  date: Date
  setDate: (date: Date) => void
  serviceArea: SupportedCities
  setServiceArea: (serviceArea: SupportedCities) => void
}

const CappingOpsManagerContext = createContext<CappingContextInterface | undefined>(undefined)

export function useCappingOpsContext(): CappingContextInterface {
  const context = useContext(CappingOpsManagerContext)
  if (!context) {
    throw new Error('cappingOpsContext is being used outside of <CappingOpsManagerContext />')
  }
  return context
}

export function CappingOpsManagerProvider({ children }: any) {
  const [date, setDate] = useState(new Date())
  const [serviceArea, setServiceArea] = useState(SupportedCities.NO_OSLO)

  return (
    <CappingOpsManagerContext.Provider
      value={{
        date,
        setDate,
        serviceArea,
        setServiceArea,
      }}
    >
      {children}
    </CappingOpsManagerContext.Provider>
  )
}
