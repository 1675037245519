import {
  Box,
  Kbd,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { useFeatureFlags } from '@wanda/shared/src/hooks/useFeatureFlags'
import React, { Suspense } from 'react'
import useKeyboardShortcut from 'use-keyboard-shortcut'

import { Search } from './Search'

const shortcutsOptions = {
  overrideSystem: true,
  ignoreInputFields: false,
  repeatOnHold: false,
}

export function SearchModal({
  isOpen,
  onOpen,
  onClose,
}: {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}) {
  const { featureFlags } = useFeatureFlags()

  useKeyboardShortcut(
    ['Meta', 'K'],
    () => {
      if (isOpen) {
        onClose()
      } else {
        onOpen()
      }
    },
    { ...shortcutsOptions, ignoreInputFields: true },
  )

  return (
    <Modal isOpen={!!featureFlags?.ENABLE_ADMIN_SEARCH_MODAL && isOpen} onClose={() => onClose()}>
      <ModalOverlay />
      <ModalContent maxWidth="90vw" width="800px">
        <ModalBody p="2">
          <Suspense fallback={<Spinner />}>
            {isOpen && <Search isOpen={isOpen} onClose={onClose} />}
          </Suspense>
          <Box display="flex" justifyContent="space-between">
            <Text as="span" fontSize="xs" fontWeight="normal">
              Navigate with <Kbd>↑</Kbd> and <Kbd>↓</Kbd>
            </Text>
            <span />
            <Text as="span" fontSize="xs" fontWeight="normal">
              Open this with <Kbd>⌘</Kbd> + <Kbd>K</Kbd>
            </Text>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
