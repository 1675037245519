import { useAuth0 } from '@auth0/auth0-react'
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Select,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import { useMutation } from '@tanstack/react-query'
import { Calendar } from '@wanda-space/noelle'
import { refreshPlandayCapacity } from '@wanda/shared/src/api-client/routes/shifts'
import { SupportedCities } from '@wanda/shared/src/consts/enums'
import { addDays, format } from 'date-fns'
import React, { useState } from 'react'

import { selectOptions } from 'utils/selectOptions'

const RefreshCapacity = () => {
  const { getAccessTokenSilently } = useAuth0()
  const toast = useToast()
  const [serviceArea, setCity] = useState<string | undefined>()
  const [fromDate, setFromDate] = useState(new Date())
  const [toDate, setToDate] = useState(addDays(new Date(), 7))

  const mutation = useMutation(
    async () => {
      const token = await getAccessTokenSilently()
      return await refreshPlandayCapacity(
        {
          serviceArea,
          fromDate: format(fromDate, 'yyyy-MM-dd'),
          toDate: format(toDate, 'yyyy-MM-dd'),
        },
        token,
      )
    },
    {
      onSuccess: () => {
        toast({
          title: 'Capacity refreshed',
          status: 'success',
        })
      },
      onError: (error) => {
        captureException(error)
        toast({
          title: 'Error refreshing capacity',
          status: 'error',
        })
      },
    },
  )

  return (
    <Card p="4" maxW="800px">
      <Flex justifyContent="space-between" alignItems="baseline">
        <Text as="h1" fontSize="2xl" fontWeight="semibold">
          Refresh capacity
        </Text>
        <ButtonGroup isAttached>
          <Button
            as="a"
            size="sm"
            href="https://wandano.planday.com/schedule"
            target="_blank"
            rel="noopener noreferrer"
            variant="outline"
          >
            Planday Norway
          </Button>
          <Button
            as="a"
            size="sm"
            href="https://wandase.planday.com/schedule"
            target="_blank"
            rel="noopener noreferrer"
            variant="outline"
          >
            Planday Sweden
          </Button>
        </ButtonGroup>
      </Flex>
      <Text as="p" fontSize="md" pt="4">
        Ensure that the capacity is set in the Planday system for the selected service area and
        dates. If the capacity is not set, the timeslots will be unavailable for booking.
      </Text>
      <Stack spacing="4" mt="4" mb="4">
        <FormControl>
          <FormLabel fontWeight="semibold">Service area</FormLabel>
          <Select id="select-city" onChange={(e) => setCity(e.target?.value)} value={serviceArea}>
            {selectOptions(SupportedCities)}
          </Select>
        </FormControl>
        <HStack spacing="4">
          <Box>
            <Text as="strong" fontWeight="semibold">
              From date
            </Text>
            <Calendar
              minDate={new Date()}
              monthsToRender={1}
              onSelectDay={setFromDate}
              selectedDate={fromDate}
              weekDays={['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']}
            />
          </Box>

          <Box>
            <Text as="strong" fontWeight="semibold">
              To date
            </Text>
            <Calendar
              minDate={fromDate}
              monthsToRender={1}
              onSelectDay={setToDate}
              selectedDate={toDate}
              weekDays={['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']}
            />
          </Box>
        </HStack>
      </Stack>

      <Button
        onClick={() => mutation.mutate()}
        isLoading={mutation.isLoading}
        isDisabled={mutation.isLoading}
      >
        Update capacity
      </Button>
    </Card>
  )
}
export default RefreshCapacity
