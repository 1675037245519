import { Flex, useMediaQuery } from '@chakra-ui/react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { Suspense, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'

import { CenterProgress } from 'components/ui/CenterProgress/CenterProgress'
import { SplitViewProvider } from 'components/ui/SplitView/SplitViewContext'
import { SIDEBAR_WIDTH, USE_DRAWER } from 'styles/consts'

import { Header } from './Header'
import { Sidebar } from './Sidebar'

export const App = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const Main = styled.main<{ showSidebar: boolean }>`
  flex: 1;
  padding: calc(2 * 4px);
  ${({ showSidebar }) =>
    showSidebar
      ? css`
          max-width: calc(100% - ${SIDEBAR_WIDTH});
        `
      : css`
          max-width: 100%;
        `}
`

export const Layout = ({
  setShowSearchModal,
}: {
  setShowSearchModal: (value: boolean) => void
}) => {
  const [shouldUseDrawer] = useMediaQuery(USE_DRAWER)
  const [showSidebar, setShowSidebar] = useState(false)

  useEffect(() => {
    setShowSidebar(!shouldUseDrawer)
  }, [shouldUseDrawer])

  return (
    <App>
      <SplitViewProvider>
        <Header
          onClickMenuIcon={() => setShowSidebar((value) => !value)}
          onClickSearch={() => setShowSearchModal(true)}
        />
        <Flex flexDirection="row" maxW="100vw" height="100%">
          <Sidebar open={showSidebar} onClose={() => setShowSidebar(false)} />
          <Suspense fallback={<CenterProgress size="3rem" />}>
            <Main showSidebar={showSidebar && !shouldUseDrawer}>
              <Outlet />
            </Main>
          </Suspense>
        </Flex>
      </SplitViewProvider>
    </App>
  )
}
